<template>
  <div
    style="padding: 5px; height: 4vh; line-height: 4vh; text-align: left"
    v-show="!IsHome"
  >
    <span style="font-size: 18px; color: rgb(94, 109, 131)" @click="backPage"
      ><el-icon><ArrowLeftBold /></el-icon>返回</span
    >
    <el-divider />
  </div>
  <router-view />
</template>

<script>
import { ElMessage } from "element-plus";

export default {
  name: "App",
  data() {
    return {
      pageTitle: "",
    };
  },
  computed: {
    IsHome() {
      console.log("当前", this.pageTitle);
      return this.pageTitle == "Home";
    },
  },
  watch: {
    //监听页面标题变化（计算属性监听document.title总是不更新）
    $route() {
      this.pageTitle = this.$route.name;
      console.log(this.pageTitle);
    },
  },
  components: {},
  created() {
    console.log("获取登录信息Index");
    const browser = this.$userInfo.getBrowser();
    if (browser == "unknow") {
      ElMessage({
        showClose: true,
        message: "未知的浏览器类型" + browser,
        type: "warning",
      });
    }
    let that = this;
    this.$apiRequest.post("/api/WeChatAPI/GetSysBase", {}, true).then((res) => {
      that.$store.commit("setBannerImg", res.data.bannerImg);
      that.$store.commit("setHospitalInfo", res.data.hosInfo);
      that.$store.commit("setSysAuth", res.data.sysAuths);
    });
    if (browser == "aliPay") {
      //通知小程序静默获取userid
      console.log("获取用户userId");
      window.aliPayJS.postMessage({
        command: "GetUserId",
      });
      window.aliPayJS.onMessage = (res) => {
        console.log("接收到支付宝登录消息", res);
        if (res.command == "GetUserId") {
          //设置登录信息
          if (res.data.UserId) {
            localStorage.setItem(
              "userInfo",
              JSON.stringify({ openid: res.data.UserId })
            );
          } else {
            localStorage.setItem(
              "userInfo",
              JSON.stringify({ openid: res.data.OpenId })
            );
          }
          //支付宝的需要调用根据openid获取用户信息的接口
          that.appLogin(true);
        }
      };
      return;
    }
    that.appLogin();
    this.connectWebSocket();
  },
  methods: {
    backPage() {
      console.log("上一级");
      this.$router.back();
    },
    appLogin(requestApi = false) {
      let that = this;
      this.$userInfo.getUserInfo(requestApi).then((uInfo) => {
        if (!uInfo) {
          //登录失败
          ElMessage({
            showClose: true,
            message: "登录失败，请使用微信浏览器浏览器打开",
            type: "error",
          });
        } else {
          that.$store.commit("setUser", uInfo);
          if (uInfo.userInfBinds && uInfo.userInfBinds.length > 0) {
            const flag = uInfo.userInfBinds
              .filter((o) => o.use_flag == 1)
              .pop();
            console.log("设置默认就诊人");
            if (flag) {
              that.$store.commit("setSelectedPatient", {
                patient: flag,
                reqApi: false,
              });
            } else {
              that.$store.commit("setSelectedPatient", {
                patient: uInfo.userInfBinds[0],
                reqApi: false,
              });
            }
          }
        }
      });
    },
    connectWebSocket() {
      if (window.httpConfig.P_WSS) {
        if (this.$store.state.selectedPatient) {
          let msg = JSON.stringify({
            openId: this.$store.state.selectedPatient.OpenId,
          });
          this.$socket.send(msg);
        }
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.footer {
  height: 50px;
}
* {
  margin: 0px;
  padding: 0px;
}
.userInfoCard {
  padding: 5px 15px 5px 15px;
  border-radius: 15px;
  text-align: left;
  height: 100px;
  background-color: #c6e2ff;
  margin-bottom: 10px;
  margin-top: 10px;
}
</style>
