// WebSocketPlugin.js
import bus from "vue3-eventbus";

const WebSocketPlugin = {
  install(Vue, options) {
    let socket = null;
    if (!window.httpConfig.P_WSS) {
      return;
    }

    const connect = () => {
      // 创建WebSocket连接
      socket = new WebSocket(options.url);

      // 监听WebSocket事件
      socket.addEventListener("open", () => {
        console.log("打开通讯");
      });

      socket.addEventListener("message", (event) => {
        let obj = JSON.parse(event.data);
        console.log("接收到消息", obj);
        // 发送事件总线消息，所有组件、页面通过监听 obj.command 实现回调业务 231219 √
        bus.emit(obj.command, {
          data: obj.data,
          code: obj.code,
          command: obj.command,
        });
      });

      socket.addEventListener("close", (event) => {
        console.log("WebSocket连接已关闭", event);
        // 断线后尝试自动重连
        setTimeout(connect, options.reconnectInterval || 1000);
      });

      // 将WebSocket实例挂载到Vue原型上，以便在整个应用中访问
      Vue.config.globalProperties.$socket = socket;
    };

    // 初始连接
    connect();
  },
};

export default WebSocketPlugin;
