import { createRouter, createWebHashHistory } from "vue-router";

// 创建路由实例
const router = createRouter({
  history: createWebHashHistory("dgxyy"),
  routes: [
    {
      path: "/Index",
      name: "Index",
      component: () => import("@/views/Index"),
      children: [
        {
          path: "/regIndex",
          name: "regIndex",
          component: () => import("@/views/registration/RegIndex.vue"),
        },
        {
          path: "/reportIndex",
          name: "reportIndex",
          component: () => import("@/views/report/ReportIndex.vue"),
        },
        {
          path: "/rechargeIndex",
          name: "rechargeIndex",
          component: () => import("@/views/recharge/RechargeIndex.vue"),
        },
        {
          path: "/userInfoIndex",
          name: "userInfoIndex",
          component: () => import("@/views/userInfo/UserInfoIndex.vue"),
        },
        {
          path: "/bindUserInfo",
          name: "BindUserInfo",
          component: () => import("@/views/userInfo/BindUserInfo.vue"),
          meta: { title: "已绑定就诊人" },
        },
        {
          path: "/deptInfo/:deptId",
          name: "DeptInfo",
          component: () => import("@/views/registration/DeptInfo.vue"),
          meta: { title: "科室挂号" },
        },
        {
          path: "/CommitReservation",
          name: "CommitReservation",
          component: () =>
            import("@/views/registration/CommitRegistration.vue"),
          meta: { title: "确认挂号信息" },
        },
        {
          path: "/CommitRegByDate",
          name: "CommitRegByDate",
          component: () => import("@/views/registration/CommitRegByDate.vue"),
          meta: { title: "确认挂号信息" },
        },
        {
          path: "/HealthcheckIndex",
          name: "healthcheckIndex",
          component: () => import("@/views/healthcheck/HealthcheckIndex.vue"),
          meta: { title: "体检预约" },
        },
        {
          path: "/PackageInfo/:packageId?",
          name: "packageInfo",
          component: () => import("@/views/healthcheck/PackageInfo.vue"),
          meta: { title: "套餐详情" },
        },
        {
          path: "/OrdList",
          name: "ordList",
          component: () => import("@/views/healthcheck/OrdList.vue"),
          meta: { title: "申请单列表" },
        },
        {
          path: "/PdfView/:pdfSrc",
          name: "pdfView",
          component: () => import("@/views/currentView/PdfView.vue"),
          meta: { title: "申请单列表" },
        },
        {
          path: "/RegistrationList",
          name: "registrationList",
          component: () => import("@/views/registration/RegistrationList.vue"),
          meta: { title: "挂号记录" },
        },
        {
          path: "/OutpatientIndex",
          name: "outpatientIndex",
          component: () =>
            import("@/views/recharge/OutPatient/OutpatientIndex.vue"),
          meta: { title: "门诊缴费" },
        },
        {
          path: "/HospitalIndex",
          name: "hospitalIndex",
          component: () =>
            import("@/views/recharge/InHospital/HospitalIndex.vue"),
          meta: { title: "住院缴费" },
        },
        {
          path: "/BalanceQuery/:price?",
          name: "balanceQuery",
          component: () =>
            import("@/views/recharge/OutPatient/BalanceQuery.vue"),
          meta: { title: "余额查询" },
        },
        {
          path: "/MedCardRecords",
          name: "medCardRecords",
          component: () =>
            import("@/views/recharge/OutPatient/MedCardRecords.vue"),
          meta: { title: "就诊卡消费记录" },
        },
        {
          path: "/OutpatientOrderList/:showType?",
          name: "outpatientOrderList",
          component: () =>
            import("@/views/recharge/OutPatient/OutpatientOrderList.vue"),
          meta: { title: "待缴费记录" },
        },
        {
          path: "/DepositQuery",
          name: "depositQuery",
          component: () =>
            import("@/views/recharge/InHospital/DepositQuery.vue"),
          meta: { title: "住院押金" },
        },
        {
          path: "/DepositRecords",
          name: "depositRecords",
          component: () =>
            import("@/views/recharge/InHospital/DepositRecords.vue"),
          meta: { title: "押金明细" },
        },
        {
          path: "/InHospitalExpense/:showType?",
          name: "inHospitalExpense",
          component: () =>
            import("@/views/recharge/InHospital/InHospitalExpense.vue"),
          meta: { title: "费用清单" },
        },
        {
          path: "/InHospitalExpenseDetail",
          name: "inHospitalExpenseDetail",
          component: () =>
            import("@/views/recharge/InHospital/InHospitalExpenseDetail.vue"),
          meta: { title: "费用清单明细" },
        },
        {
          path: "/InHospitalNotice",
          name: "inHospitalNotice",
          component: () =>
            import("@/views/recharge/InHospital/InHospitalNotice.vue"),
          meta: { title: "住院通知单查询" },
        },
        {
          path: "/MessageIndex",
          name: "messageIndex",
          component: () => import("@/views/message/MessageIndex.vue"),
          meta: { title: "消息列表" },
        },
        {
          path: "/MessageInfo",
          name: "messageInfo",
          component: () => import("@/views/message/MessageInfo.vue"),
          meta: { title: "消息详情" },
        },
        {
          path: "/PayOrderIndex",
          name: "payOrderIndex",
          component: () => import("@/views/payOrder/PayOrderIndex.vue"),
          meta: { title: "加载中..." },
        },

        {
          path: "/CreateMedical",
          name: "createMedical",
          component: () => import("@/views/medical/CreateMedical.vue"),
          meta: { title: "病历服务" },
        },
        {
          path: "/MedicalIndex",
          name: "medicalIndex",
          component: () => import("@/views/medical/MedicalIndex.vue"),
          meta: { title: "病历邮寄申请" },
        },
        {
          path: "/MedicalList",
          name: "medicalList",
          component: () => import("@/views/medical/MedicalList.vue"),
          meta: { title: "申请记录" },
        },
        {
          path: "/MedicalInfo/:id",
          name: "medicalInfo",
          component: () => import("@/views/medical/MedicalInfo.vue"),
          meta: { title: "申请记录" },
        },
        {
          path: "/SurveyIndex",
          name: "SurveyIndex",
          component: () => import("@/views/survey/SurveyIndex.vue"),
          meta: { title: "满意度调查" },
        },

        {
          path: "/",
          name: "Home",
          component: () => import("@/views/Home"),
          meta: { title: "首页" },
        },
        {
          path: "/:catchAll(.*)",
          component: () => import("@/views/errorView/404"), // 404 页面的组件路径
        },
      ],
    },

    {
      path: "/userMedInfo",
      name: "UserMedInfo",
      component: () => import("@/views/YdzfView/UserMedInfo"),
      meta: { title: "参保信息查询" },
    },
    // 可以添加其他路由规则
  ],
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
export default router;
