// store/index.js
import { createStore } from "vuex";
import apiRequest from "@/utils/api";
import { ElMessage } from "element-plus";

const store = createStore({
  state() {
    return {
      user: null, // 用户登录信息，可以是一个包含用户信息的对象
      selectedPatient: null, // 当前选中的患者信息，可以是一个包含患者信息的对象
      patients: null,
      bannerImg: [],
      sysAuth: [],
      hospitalInfo: null,
    };
  },
  mutations: {
    setUser(state, user) {
      localStorage.setItem("userInfo", JSON.stringify(user));
      state.user = user;
      if (user.userInfBinds) {
        state.patients = user.userInfBinds;
      }
    },
    setSelectedPatient(state, { patient, reqApi }) {
      console.log(patient);
      //如果没有绑定患者信息
      if (patient == undefined) {
        state.selectedPatient = patient;
        return;
      }
      if (reqApi) {
        apiRequest
          .post(
            "/api/WechatApi/SelectedPatient?uIdNumber=" + patient.IdNumber,
            {}
          )
          .then((res) => {
            console.log("设置首选接口返回", res);
            if (!res.status) {
              ElMessage.error("设置首选失败。");
            }
          })
          .catch((res) => {
            console.log(res);
            ElMessage.error("设置首选失败。");
          });
      }
      localStorage.setItem("patient", JSON.stringify(patient));
      patient.use_flag = 1;
      state.selectedPatient = patient;
      let user = state.user;
      if (user && user.userInfBinds) {
        user.userInfBinds.forEach((item) => {
          if (item.IdNumber != patient.IdNumber) {
            item.use_flag = 0;
          } else {
            item.use_flag = 1;
          }
        });
      }
      this.commit("setUser", user);
    },
    setBannerImg(state, bannerImg) {
      state.bannerImg = bannerImg;
    },
    setSysAuth(state, sysAuth) {
      state.sysAuth = sysAuth;
    },
    setHospitalInfo(state, hospitalInfo) {
      state.hospitalInfo = hospitalInfo;
    },
  },
  actions: {
    // 可以在这里定义异步操作，然后提交 mutations
  },
  getters: {
    // 可以在这里定义派生状态，如计算属性
  },
});

export default store;
